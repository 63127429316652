.modal-main-div {
    position: fixed;
    z-index: 20;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #F1F1F1;
    width: 80%;
    height: 80%;
    border-radius: 0.8rem;
    overflow: hidden;
}

.header-bg{
    background-color: #91919145;
}

.table-container {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    overflow-x: auto;
    overflow-y: auto;
}

.table-heading th {
    font-weight: 400 ;
    /* background-color: #91919145; */
    height: 60px;
    font-size: 1.1rem;
    color: #000;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    min-width: 70px;
    text-transform: capitalize;
}

.excel-table{
    width: 100%;
}

.excel-table tr {
    border-style: none;
    border-bottom: 1px solid black;
}

.table-data td {
    padding: 1rem;
    text-align: center;
    font-size: 0.9vw;
    font-weight: 600;
    background-color: #F7F7F7;
}