.aweCheckbox {
    padding-left: 20px;
  }
  .aweCheckbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 0 20px 0 10px;
    cursor: pointer;
  }
  .aweCheckbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }
  .aweCheckbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
  }
  .aweCheckbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
  }
  .aweCheckbox input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  .aweCheckbox input[type="checkbox"]:checked + label::after {
    font-family: "FontAwesome";
    content: "\f00c";
  }
  .aweCheckbox input[type="checkbox"]:indeterminate + label::after {
    display: block;
    content: "";
    width: 10px;
    height: 3px;
    background-color: #555555;
    border-radius: 2px;
    margin-left: -16.5px;
    margin-top: 7px;
  }
  .aweCheckbox input[type="checkbox"]:disabled + label {
    opacity: 0.65;
  }
  .aweCheckbox input[type="checkbox"]:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed;
  }
  .aweCheckbox.aweCheckbox-circle label::before {
    border-radius: 50%;
  }
  .aweCheckbox.aweCheckbox-inline {
    margin-top: 0;
  }
  .aweCheckbox-danger input[type="checkbox"]:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
  }
  .aweCheckbox-danger input[type="checkbox"]:checked + label::after {
    color: #fff;
  }
  .aweCheckbox-danger input[type="checkbox"]:indeterminate + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
  }
  .aweCheckbox-danger input[type="checkbox"]:indeterminate + label::after {
    background-color: #fff;
  }
  input[type="checkbox"].styled:checked + label:after {
    font-family: 'FontAwesome';
    content: "\f00c";
  }
  input[type="checkbox"] .styled:checked + label::before {
    color: #fff;
  }
  input[type="checkbox"] .styled:checked + label::after {
    color: #fff;
  }

  .ql-editor {
    min-height: 100px;
  }
  