.chat-msg{
    max-width: 350px;
    width: max-content;
    font-size:"15px";
}

.input-box{
    width: 100%;
    padding: 0 70px;
}

@media (max-width: 450px) {
    .chat-msg{
        max-width: 280px;
    }
    .input-box{
        padding: 0 10px !important;
    }
}

@media (max-width: 300px) {
    .chat-msg{
        max-width: 250px;
    }
}
